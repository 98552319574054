import ax from "axios";

ax.defaults.headers.post["Content-Type"] = "application/json";
ax.defaults.validateStatus = (status) => status >= 200 && status < 500; // default

const axios = ax.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
});

const token = localStorage.getItem("userToken");
token && (axios.defaults.headers.common.Authorization = `Token ${token}`);
axios.CancelToken = ax.CancelToken;
axios.isCancel = ax.isCancel;

export default axios;
 