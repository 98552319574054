import Vue from "vue";
import { InlineSvgPlugin } from "vue-inline-svg";
import Vuex from "vuex";
import { account } from "./account.module";
import { alert } from "./alert.module";

Vue.use(InlineSvgPlugin);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    alert,
  },
});
