const initialState = {
  type: null,
  message: null,
  timeout: 3000,
};

const actions = {
  success({ commit }, message) {
    commit("success", message);
  },
  error({ commit }, message) {
    commit("error", message);
  },
  clear({ commit }) {
    commit("clear");
  },
  information({ commit }, message) {
    commit("information", message);
  },
};

const mutations = {
  success(state, message) {
    state.type = "success";
    state.message = message.message ? message.message : message;
    state.timeout = message.timeout ? message.timeout : 3000;
  },
  error(state, message) {
    state.type = "error";
    state.message = message;
    state.timeout = 3000;
  },
  information(state, message) {
    state.type = "#FFB81C";
    state.message = message.message ? message.message : message;
    state.timeout = message.timeout ? message.timeout : 3000;
  },
  clear(state) {
    state.type = null;
    state.message = null;
  },
};

// eslint-disable-next-line import/prefer-default-export
export const alert = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
