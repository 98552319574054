<template>
  <v-app class="access-pages">
    <!-- <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar> -->

    <v-main :class="this.user.token ? 'main-app' : ''">
      <v-snackbar
        :value="alert.message && !!alert.message.length"
        :color="alert.type"
        :left="true"
        :timeout="alert.timeout"
        @input="clearAlert()"
      >
        {{ alert.message }}
      </v-snackbar>
      <router-view />
    </v-main>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import "@/scss/global.scss";
import { mapState, mapActions } from "vuex";

export default {
  name: "LeadPartnerDialog",
  data: () => ({
    overlay: false,
  }),

  mounted() {
    // hide the overlay when everything has loaded
    setTimeout(() => {
      this.overlay = false;
    }, 1000);
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("account", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
  },
};
</script>
